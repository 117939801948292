import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import PropTypes from "prop-types";
import Header from "../layout/Header";
import Flashmessages from "../flashMessagesComp/flashMessages";

import { API_CONSTANTS } from "../../shared/constants/api.constants";
import {
  sendFlashMessage,
  clearcurrentFlashMessage,
} from "../../actions/flashMessage";
import Loader from "../common/GlobalLoader";
import Select from "react-select";
import Rates from "../rates/Rates";

class PremiumPackages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      singleuserbyiddt: {},
      premiumPackageData: {
        counsilId: "",
        premiumPackage: "",
        license: "",
        packageMessage: "",
        packageIcon: "",
        active: false,
        packageOrder: "",
      },
      loading: false,
      activeOptions: [
        { label: "Yes", value: true },
        { label: "No", value: false },
      ],
      premiumPackageList: [],
      premiumPackageId: null,
    };
    this.onPremiumPackageSubmit = this.onPremiumPackageSubmit.bind(this);
    this.onPremiumPackageChange = this.onPremiumPackageChange.bind(this);
    this.onIconImageChange = this.onIconImageChange.bind(this);
  }
  componentDidMount() {
    this.getPremiumPackages();
    this.clearPremiumPackage();
  }
  onPremiumPackageSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ loading: true });

    let premiumPackageData = this.state.premiumPackageData;
    let councilId = this.props.match.params.id;

    let data = {
      councilId,
      premiumPackage: premiumPackageData.premiumPackage,
      license: premiumPackageData.license,
      packageMessage: premiumPackageData.packageMessage,
      packageIcon: premiumPackageData.packageIcon,
      active: premiumPackageData.active,
      packageOrder: premiumPackageData.packageOrder,
    };

    if (premiumPackageData.id) {
      // EDIT
      axios
        .put(
          `${API_CONSTANTS.COUNCIL.EDIT_PREMIUM_PACKAGE}/${premiumPackageData.id}`,
          data
        )
        .then((res) => {
          this.props.sendFlashMessage(
            "Premium package updated successfully !!",
            "alert-success"
          );
          setTimeout(() => {
            this.props.clearcurrentFlashMessage();
          }, 2000);
          this.setState({ loading: false });
          this.clearPremiumPackage();
          this.getPremiumPackages();
        })
        .catch((err) => {
          this.setState({ loading: false });
          console.log("error is : " + err);
          this.props.sendFlashMessage(
            "Something went wrong !!",
            "alert-danger"
          );
          setTimeout(() => {
            this.props.clearcurrentFlashMessage();
          }, 2000);
        });
    } else {
      // NEW
      axios
        .post(`${API_CONSTANTS.COUNCIL.CREATE_PREMIUM_PACKAGE}`, data)
        .then((res) => {
          this.props.sendFlashMessage(
            "Premium package created successfully !!",
            "alert-success"
          );
          setTimeout(() => {
            this.props.clearcurrentFlashMessage();
          }, 2000);
          this.setState({ loading: false });
          this.clearPremiumPackage();

          this.getPremiumPackages();
        })
        .catch((err) => {
          this.setState({ loading: false });
          console.log("error is : " + err);
          this.props.sendFlashMessage(
            "Something went wrong !!",
            "alert-danger"
          );
          setTimeout(() => {
            this.props.clearcurrentFlashMessage();
          }, 2000);
        });
    }
  }
  onPremiumPackageChange(e) {
    const state = this.state.premiumPackageData;
    state[e.target.name] = e.target.value;
    this.setState({ premiumPackageData: state });
  }
  onIconImageChange(e) {
    let IconImageFormData = new FormData();
    IconImageFormData.append("files", e.target.files[0]);
    const state = this.state.premiumPackageData;
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    axios
      .post(`${API_CONSTANTS.COUNCIL.UPLOADS_MULTIPLE}`, IconImageFormData)
      .then((res) => {
        if (res && res.data && res.data.length) {
          state["packageIcon"] = res.data[0];
          this.setState({ premiumPackageData: state });
        }
      })
      .catch((err) => {
        this.props.sendFlashMessage(
          "Something went wrong while uploading icon!!",
          "alert-danger"
        );
        setTimeout(() => {
          this.props.clearcurrentFlashMessage();
        }, 2000);
      });
  }
  getPremiumPackages = () => {
    let id = this.props.match.params.id;
    this.setState({ loading: true });
    axios
      .get(`${API_CONSTANTS.COUNCIL.GET_PREMIUM_PACKAGES}/${id}`)
      .then((res) => {
        this.setState({ loading: false });
        this.setState({ premiumPackageList: res.data });
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log("error premiumPackageList is : " + err);
      });
  };
  fileInputRef = React.createRef();
  clearPremiumPackage = (callback) => {
    this.setState(
      {
        premiumPackageData: {
          counsilId: "",
          premiumPackage: "",
          license: "",
          packageMessage: "",
          packageIcon: "",
          active: false,
          packageOrder: "",
        },
      },
      () => callback && callback()
    );
    this.fileInputRef.current.value = null;
  };

  editCouncilPremiumPackage = (e, packageId) => {
    this.clearPremiumPackage(() => {
      let _package = this.state.premiumPackageList.find(
        (_package) => _package.id == packageId
      );

      if (_package && _package.id) {
        let data = {
          id: _package.id,
          councilId: _package.councilId,
          premiumPackage: _package.premiumPackage,
          license: _package.license,
          packageMessage: _package.packageMessage,
          packageIcon: _package.packageIcon,
          active: _package.active,
          packageOrder: _package.packageOrder,
        };

        this.setState({ premiumPackageData: data });
      }
    });
  };

  deleteCouncilPremiumPackage = (e, packageId) => {
    axios
      .delete(`${API_CONSTANTS.COUNCIL.DELETE_PREMIUM_PACKAGE(packageId)}`)
      .then((res) => {
        this.props.sendFlashMessage(
          "Premium package deleted successfully !!",
          "alert-success"
        );
        setTimeout(() => {
          this.props.clearcurrentFlashMessage();
        }, 2000);
        this.setState({ loading: false });
        this.getPremiumPackages();
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log("error is : " + err);
        this.props.sendFlashMessage("Something went wrong !!", "alert-danger");
        setTimeout(() => {
          this.props.clearcurrentFlashMessage();
        }, 2000);
      });
  };

  handleActiveChange = (event) => {
    const state = this.state.premiumPackageData;
    state["active"] = event.value;
    this.setState({ premiumPackageData: state });
  };

  render() {
    const { premiumPackageData, premiumPackageList } = this.state;
    return (
      <>
        <div
          class="modal fade new__modal rate-currenices-modal "
          id="premiumPackageModal"
          data-keyboard="false"
          data-backdrop="static"
          tabindex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div
            class="modal-dialog modal-xl our-custom-modal"
            role="document"
            style={{ maxWidth: "1245px" }}
          >
            <div
              class="modal-content"
              style={{ maxHeight: `calc(100vh - 64px)`, overflow: "auto" }}
            >
              <div class="custom-modal-header">
                <div class="modal-header">
                  <h5 class="modal-title">Premium Packages</h5>
                  <button
                    onClick={() => this.clearPremiumPackage()}
                    type="button"
                    class="close closed__btn"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span class="icon-cross"></span>
                  </button>
                </div>
              </div>
              <div class="modal-body">
                <div className={`show-advertisement pd-t-20 mg-t-20`}>
                  <h5 class="mg-b-20 text-center">
                    <strong>Packages</strong>
                  </h5>
                  <form onSubmit={this.onPremiumPackageSubmit}>
                    <ul class="list-advertisement list-adds">
                      <li>
                        <div class="row">
                          <div class="col-sm-4 text-left text-sm-right">
                            <label class="text-label pd-t-5">
                              Package Name
                            </label>
                          </div>
                          <div class="col-sm-6">
                            <input
                              type="text"
                              class="form-control"
                              placeholder=""
                              value={premiumPackageData.premiumPackage}
                              name="premiumPackage"
                              onChange={this.onPremiumPackageChange}
                            />
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-sm-4 text-left text-sm-right">
                            <label class="text-label pd-t-5">License</label>
                          </div>
                          <div class="col-sm-6">
                            <input
                              type="text"
                              class="form-control"
                              placeholder=""
                              value={premiumPackageData.license}
                              name="license"
                              onChange={this.onPremiumPackageChange}
                            />
                          </div>
                        </div>
                      </li>

                      <li>
                        <div class="row">
                          <div class="col-sm-4 text-left text-sm-right">
                            <label class="text-label pd-t-5">
                              Package Message
                            </label>
                          </div>
                          <div class="col-sm-6">
                            <textarea rows="4"
                              class="form-control"
                              placeholder=""
                              value={premiumPackageData.packageMessage}
                              name="packageMessage"
                              onChange={this.onPremiumPackageChange}
                            />
                          </div>
                        </div>
                      </li>

                      <li>
                        <div class="row">
                          <div class="col-sm-4 text-left text-sm-right">
                            <label class="text-label pd-t-5">
                              Package Icon
                            </label>
                          </div>
                          <div class="col-sm-6">
                            <input
                              type="file"
                              id="exampleFormControlFile1"
                              name="newreconciliationfile"
                              onChange={this.onIconImageChange}
                              ref={this.fileInputRef}
                            />
                            {premiumPackageData.packageIcon && (
                              <div
                                style={{
                                  marginTop: "10px",
                                  height: "50%",
                                  width: "50%",
                                  objectFit: "cover",
                                }}
                              >
                                <img src={premiumPackageData.packageIcon} />
                              </div>
                            )}
                          </div>
                        </div>
                      </li>

                      <li>
                        <div class="row">
                          <div class="col-sm-4 text-left text-sm-right">
                            <label class="text-label pd-t-5">Is Active</label>
                          </div>
                          <div class="col-sm-6">
                            <Select
                              value={this.state.activeOptions.filter(
                                (option) =>
                                  option.value == premiumPackageData.active
                              )}
                              onChange={this.handleActiveChange}
                              options={this.state.activeOptions}
                            />
                          </div>
                        </div>
                      </li>

                      <li>
                        <div class="row">
                          <div class="col-sm-4 text-left text-sm-right">
                            <label class="text-label pd-t-5">
                              Package Order
                            </label>
                          </div>
                          <div class="col-sm-6">
                            <input
                              type="number"
                              class="form-control"
                              placeholder=""
                              value={premiumPackageData.packageOrder}
                              name="packageOrder"
                              onChange={this.onPremiumPackageChange}
                            />
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="text-center">
                          <button type="submit" class="btn btn-secondary">
                            Submit Package
                          </button>
                        </div>
                      </li>
                    </ul>
                  </form>

                  <div class="table-box">
                    <div class="table-responsive">
                      <table class="table" style={{ minWidth: "900px" }}>
                        <thead>
                          <tr>
                            <th style={{ width: "20%" }}>Package Name</th>
                            <th style={{ width: "20%" }}>License</th>
                            <th style={{ width: "20%" }}>Package Message</th>
                            <th style={{ width: "10%" }}>Package Icon</th>
                            <th style={{ width: "10%" }}>Is Active</th>
                            <th style={{ width: "10%" }}>Package Order</th>
                            <th style={{ width: "5%" }}>Rates</th>
                            <th class="text-center" style={{ width: "10%" }}>
                              Edit
                            </th>
                            <th class="text-center" style={{ width: "5%" }}>
                              Del
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {premiumPackageList.map((_package, i) => (
                            <tr key={i}>
                              <td>{_package.premiumPackage}</td>
                              <td>{_package.license}</td>
                              <td>{_package.packageMessage}</td>
                              <td>
                                <div
                                  style={{
                                    overflow: "hidden",
                                    height: "10%",
                                    width: "30%",
                                  }}
                                >
                                  <img src={_package.packageIcon} />
                                </div>
                              </td>
                              <td>
                                {this.state.activeOptions.find(
                                  (option) => option.value == _package.active
                                ) &&
                                  this.state.activeOptions.find(
                                    (option) => option.value == _package.active
                                  )["label"]}
                              </td>
                              <td>{_package.packageOrder}</td>
                              <td>
                                <button
                                  type="button"
                                  class="btn btn-secondary"
                                  data-toggle="modal"
                                  data-target="#rateCurrenicesPackageModal"
                                  onClick={() =>
                                    this.setState({
                                      premiumPackageId: _package.id,
                                    })
                                  }
                                >
                                  Rates
                                </button>
                              </td>
                              <td class="text-center">
                                <a
                                  href="javascript:void(0)"
                                  class="btn btn-primary text-edit"
                                  onClick={(e) =>
                                    this.editCouncilPremiumPackage(
                                      e,
                                      _package.id
                                    )
                                  }
                                >
                                  <span class="icon-edit"></span>
                                </a>
                              </td>
                              <td class="text-center">
                                <a
                                  href="javascript:void(0)"
                                  class="btn btn-primary text-edit"
                                  onClick={(e) =>
                                    this.deleteCouncilPremiumPackage(
                                      e,
                                      _package.id
                                    )
                                  }
                                >
                                  <span class="icon-cross"></span>
                                </a>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Rates
          auth={this.props.auth}
          premiumPackageId={this.state.premiumPackageId}
          singleuserbyiddt={this.props.singleuserbyiddt}
          sendFlashMessage={this.props.sendFlashMessage}
          clearcurrentFlashMessage={this.props.clearcurrentFlashMessage}
        />
      </>
    );
  }
}
PremiumPackages.propTypes = {
  auth: PropTypes.object.isRequired,
  sendFlashMessage: PropTypes.func.isRequired,
  clearcurrentFlashMessage: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  sendFlashMessage,
  clearcurrentFlashMessage,
})(withRouter(PremiumPackages));
